body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.remirror-editor {
  outline: 0px solid transparent;
  height: 100%;
  font-size: 15px;
}

.remirror-is-empty:first-of-type::before {
  position: absolute;
  color: #aaa;
  pointer-events: none;
  height: 0;
  content: attr(data-placeholder);
}
