.container {
  margin-top: 15px;
}

.routeContainer {
  margin-top: 45px;
  margin-bottom: 15px;
}
.userName {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0;
}

.userStats {
  font-size: 15px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 40px;
}

.followContainer {
  margin-top: 20px;
}

.followTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.userNameContainer {
  margin-bottom: 12px;
  display: flex;
}

.twitterAvatar {
  flex: 0 0 65px;
  margin-right: 10px;
}
.twitterAvatar img {
  width: 100%;
}

.userNameText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
}

.userNameSubheading {
  font-size: 14px;
  color: #757575;
  overflow: hidden;
  text-overflow: ellipsis;
}
.idButtons {
  margin-bottom: 15px;
}

.followButton {
  margin-top: 10px;
}
